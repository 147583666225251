<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'TabItem',
    props: {
      // используется в родительском компоненте
      name: { required: true },
      selected: { default: false },
      disabled: { default: false },
    },
    data() {
      return {
        isActive: false,
        isDisabled: false,
      };
    },
    computed: {
      // Используется в родительском компоненте
      // eslint-disable-next-line
      href() {
        return '#' + this.name.toLowerCase().replace(/ /g, '-');
      },
    },
    mounted() {
      this.isActive = this.selected;
      this.isDisabled = this.disabled;
    },
    created() {
      this.$parent.tabs.push(this);
    },
    beforeUnmount() {
      const index = this.$parent.tabs.indexOf(this);
      if (index > -1) {
        // only splice array when item is found
        this.$parent.tabs.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    watch: {
      disabled: {
        handler: function (val) {
          this.isDisabled = val;
        },
      },
    },
  };
</script>
