<template>
  <div class="generalInfoScenarioTab custom-form__block">
    <div class="custom-form__inputBlock">
      <p class="custom-form__label required">Название сценария</p>
      <ComponentInput v-model="getCardName" mod="rectangular" required maxLength="255" placeholder="Введите" />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label required">Товары</p>
      <SelectionGoods
        :multiple="false"
        :selectedIdsProps="getCardScenarioProductIds"
        @updateSelectedIDs="updateSelectedIDs"
        required
      />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label required">Тип торговой операции</p>
      <SelectSingleComponentCheckbox
        v-model="getCardTradingOperationId"
        placeholder="Выберите"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="getTypeOfTradingOperationList"
        required
      />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label required">Страна отправления</p>
      <SelectSingleComponentCheckbox
        v-model="getCardDepartureCountryIds"
        placeholder="Выберите"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="getOptionDepartureCountry"
        :disabled="getCardTradingOperationId === TRADING_OPERATION_ID.export"
        required
      />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label required">Страна назначения</p>
      <SelectSingleComponentCheckbox
        v-model="getCardDestinationCountryIds[0]"
        placeholder="Выберите"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="getOptionDestinationCountry"
        :disabled="getCardTradingOperationId === TRADING_OPERATION_ID.import"
        required
      />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label">Страны транзита</p>
      <SelectSingleComponentCheckbox
        v-model="getCardScenarioTransitCountryIds[0]"
        placeholder="Выберите"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="getOptionCardScenarioTransitCountry"
      />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label required">Транспорт</p>
      <SelectSingleComponentCheckbox
        v-model="getCardScenarioTransportIds[0]"
        placeholder="Выберите"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="getTransitTransportList"
        required
      />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label">Описание</p>
      <ComponentInput
        v-model="getCardDescription"
        type="textarea"
        mod="rectangular"
        placeholder="Введите"
        :textareaStyles="{ 'min-height': '140px' }"
      />
    </div>

    <div class="custom-form__inputBlock">
      <p class="custom-form__label">Публикация на портале</p>
      <div class="checkboxInput">
        <input type="checkbox" v-model="getCardIsActive" id="chActive" />
        <label for="chActive" class="custom-form__label">Опубликован</label>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import ComponentInput from '@/common/components/ComponentInput';
  import SelectionGoods from '@/common/components/redesigned/SelectionGoods.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';

  import { TRADING_OPERATION_ID } from '../../utils/constants';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives/index';

  export default {
    emits: [
      'update:cardName',
      'update:cardScenarioProductIds',
      'update:cardTradingOperationId',
      'update:cardDepartureCountryIds',
      'update:cardScenarioDestinationCountryIds',
      'update:cardScenarioTransitCountryIds',
      'update:cardScenarioTransportIds',
      'update:cardDescription',
      'update:cardIsActive',
    ],
    components: {
      ComponentInput,
      SelectSingleComponentCheckbox,
      SelectionGoods,
    },
    props: {
      cardName: {
        type: String,
        required: true,
      },
      cardScenarioProductIds: {
        type: Array,
        required: true,
      },
      cardTradingOperationId: {
        type: String,
        required: true,
      },
      cardDepartureCountryIds: {
        type: Array,
        required: true,
      },
      cardDestinationCountryIds: {
        type: Array,
        required: true,
      },
      cardScenarioTransitCountryIds: {
        type: Array,
        required: true,
      },
      cardScenarioTransportIds: {
        type: Array,
        required: true,
      },
      cardDescription: {
        type: String,
        required: true,
      },
      cardIsActive: {
        type: [Boolean, null],
        required: true,
      },
    },

    data() {
      return {
        scenarioProductIds: this.cardScenarioProductIds,
        // transitCountries: [],
        TRADING_OPERATION_ID: TRADING_OPERATION_ID,
      };
    },
    created() {
      this.fetchCountries();
      this.fetchTypeOfTradingOperation();
      this.fetchTransitTransport();
    },
    methods: {
      ...mapActions({
        fetchCountries: DIRECTIVES_ACTIONS_NAME.countriesGet,
        fetchTypeOfTradingOperation: DIRECTIVES_ACTIONS_NAME.getTypeOfTradingOperation,
        fetchTransitTransport: DIRECTIVES_ACTIONS_NAME.getTransport,
      }),

      updateSelectedIDs(ids) {
        this.$emit('update:cardScenarioProductIds', ids);
      },

      // loadListTransitCountries() {
      //   CountryApi.search({
      //     pageNumber: 1,
      //     pageSize: 0,
      //     destCountryIds:
      //       this.getCardDepartureCountryIds.length && this.getCardDepartureCountryIds != 'null'
      //         ? this.cardDepartureCountryIds
      //         : '',
      //     isActive: true,
      //   }).then((response) => {
      //     this.transitCountries = response.data.items.map((x) => ({ text: x.name, id: x.id }));
      //   });
      // },
    },
    watch: {
      cardScenarioProductIds(value) {
        this.scenarioProductIds = value;
      },
    },
    computed: {
      ...mapGetters({
        getCountryList: DIRECTIVES_GETTERS_NAME.getCountriesSelect,
        getTypeOfTradingOperationList: DIRECTIVES_GETTERS_NAME.getTypeOfTradingOperationList,
        getTransitTransportList: DIRECTIVES_GETTERS_NAME.getTransportList,
        getCountryNoRussia: DIRECTIVES_GETTERS_NAME.getCountryNoRussia,
      }),
      getCardName: {
        get() {
          return this.cardName;
        },
        set(value) {
          this.$emit('update:cardName', value);
        },
      },
      getCardScenarioProductIds: {
        get() {
          return this.scenarioProductIds;
        },
        set(value) {
          this.$emit('update:cardScenarioProductIds', value);
        },
      },
      // раньше были мультиселекты. Из-за обратной совместимости необходимо отправлять массив
      getCardTradingOperationId: {
        get() {
          return this.cardTradingOperationId;
        },
        set(value) {
          this.$emit('update:cardTradingOperationId', value);

          if (value === TRADING_OPERATION_ID.import) {
            this.$emit('update:cardDestinationCountryIds', [Constants.countryRussiaId]);
            this.$emit('update:cardDepartureCountryIds', []);
          }
          if (value === TRADING_OPERATION_ID.export) {
            this.$emit('update:cardDepartureCountryIds', [Constants.countryRussiaId]);
            this.$emit('update:cardDestinationCountryIds', []);
          }
        },
      },
      getCardDepartureCountryIds: {
        get() {
          return this.cardDepartureCountryIds;
        },
        set(value) {
          this.$emit('update:cardDepartureCountryIds', [value]);
        },
      },
      getCardDestinationCountryIds: {
        get() {
          return this.cardDestinationCountryIds;
        },
        set(value) {
          this.$emit('update:cardDestinationCountryIds', [Number(value)]);
        },
      },
      getCardScenarioTransitCountryIds: {
        get() {
          return this.cardScenarioTransitCountryIds;
        },
        set(value) {
          this.$emit('update:cardScenarioTransitCountryIds', [Number(value)]);
        },
      },
      getCardScenarioTransportIds: {
        get() {
          return this.cardScenarioTransportIds;
        },
        set(value) {
          this.$emit('update:cardScenarioTransportIds', [Number(value)]);
        },
      },
      getCardDescription: {
        get() {
          return this.cardDescription;
        },
        set(value) {
          this.$emit('update:cardDescription', value);
        },
      },
      getCardIsActive: {
        get() {
          return this.cardIsActive;
        },
        set(value) {
          this.$emit('update:cardIsActive', value);
        },
      },

      getOptionDepartureCountry() {
        return this.getCardDestinationCountryIds == Constants.countryRussiaId
          ? this.getCountryNoRussia
          : this.getCountryList;
      },
      getOptionDestinationCountry() {
        return this.getCardDepartureCountryIds == Constants.countryRussiaId
          ? this.getCountryNoRussia
          : this.getCountryList;
      },
      getOptionCardScenarioTransitCountry() {
        if (this.getCardTradingOperationId == TRADING_OPERATION_ID.import && this.getCardDepartureCountryIds.length) {
          return this.getCountryNoRussia.filter((i) => i.id != this.getCardDepartureCountryIds[0]);
        }
        if (this.getCardTradingOperationId == TRADING_OPERATION_ID.export && this.getCardDestinationCountryIds.length) {
          return this.getCountryNoRussia.filter((i) => i.id != this.getCardDestinationCountryIds[0]);
        }
        return this.getCountryNoRussia;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';

  .generalInfoScenarioTab {
    margin-top: 24px;
    max-width: 848px;
  }
</style>
