<template>
  <div class="tabs-redesign">
    <ul class="tabs-redesign__list">
      <li
        class="tabs-redesign__item"
        v-for="(tab, index) in tabs"
        :key="tab.id"
        @click.prevent="selectTab(tab, index)"
        :class="[{ selected: tab.isActive }, { disabled: tab.isDisabled }]"
      >
        <a :href="tab.href" @click.prevent="selectTab(tab, index)">{{ tab.name }}</a>
      </li>
    </ul>

    <div class="tabs-redesign__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tab',
    emits: ['select'],
    props: ['modelValue'],
    data() {
      return {
        tabs: [],
        selectedTab: {},
        selectedTabIndex: 0,
      };
    },
    methods: {
      selectTab(selectedTab, index) {
        this.selectedTab = selectedTab;
        this.tabs.forEach((tab) => {
          tab.isActive = tab.name === selectedTab.name;
        });
        this.selectedTabIndex = index;
        this.$emit('select', index);
        this.$emit('update:modelValue', index);
      },
    },
    watch: {
      modelValue: {
        handler: function (val) {
          this.$forceUpdate();
          if (val !== this.selectedTabIndex) {
            for (let i = 0; i < this.tabs.length; i++) {
              this.tabs[i].isActive = i === val;
            }
            this.selectedTabIndex = val;
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tabs-redesign {
    &__list {
      margin: 0;
      padding: 0;
      width: fit-content;
      display: flex;
      overflow-x: auto;
      width: 100%;
      position: relative;
      z-index: 1;
      -ms-text-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid $light-grey-5;
        left: 0;
        top: 0;
      }
    }

    &__item {
      position: relative;
      z-index: 1;
      padding: 10px 24px;
      cursor: pointer;
      margin-right: 20px;
      white-space: nowrap;
      font: 15px / 20px 'Fira Sans';

      a {
        color: $black-3;
        font: 15px / 20px 'Fira Sans';
        transition: 0.5s ease;
        letter-spacing: -0.3px;
      }

      &:last-child {
        margin-right: 0;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 4px 4px 0px 0px;
        z-index: -1;
      }

      &::before {
        background: $light-blue;
      }
      &::after {
        background: var(--color, linear-gradient(90deg, #0078c8 0%, #01a39d 100%));
        opacity: 0;
        transition: 0.5s ease;
      }

      &.selected {
        pointer-events: none;
      }

      &:hover,
      &:active,
      &.selected {
        &::after {
          opacity: 1;
        }

        a {
          color: #fff;
        }
      }
    }

    @media (max-width: $laptop) {
      &__list {
        margin: 0 -14px;
        padding: 0 14px;
        width: calc(100vw - 17px);
      }
    }
  }
</style>
